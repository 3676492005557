import { Button, THEME } from "@myloc/myloc-gui";
import PropTypes from "prop-types";
import { useEffect } from "react";

import classNames from "classnames";
import { useTranslate } from "../../../language/i18n";
import pages from "../../../utils/pages";
import { BankIDLoginAlternatives } from "../../shared/BankId/BankIdAuthentication";
import { BANKID } from "../../shared/BankId/constants";
import SpinningButton from "../../shared/Buttons/MuiButton/MuiButton";
import Login from "../Login/Login";
import useAvailableLoginMethods, { LOGIN_METHODS } from "./hooks/useAvailableLoginMethods";
import useSelectedLoginMethod from "./hooks/useSelectedLoginMethod";
import styles from "./LoginWelcome.module.scss";
import Welcome from "./Welcome";

const LoginFormLogin = ({ isSelected, onSelect, ...props }) => {
  const translate = useTranslate();
  return (
    <div className={styles.loginFormContainer} onClick={onSelect}>
      {isSelected ? (
        <Login {...props} registerUrl={pages.REGISTER} forgotPasswordUrl={"/"} />
      ) : (
        <SpinningButton>{translate("AUTHENTICATE_WITH") + " " + translate("USERNAME")}</SpinningButton>
      )}
    </div>
  );
};
LoginFormLogin.propTypes = {
  isSelected: PropTypes.bool.isRequired,
  onSelect: PropTypes.func.isRequired,
};

const BankIDSelector = ({ isSelected, onSelect, children }) => {
  const translate = useTranslate();

  return (
    <>
      {isSelected ? (
        children
      ) : (
        <div onClick={onSelect}>
          <SpinningButton>{translate("AUTHENTICATE_WITH") + " " + BANKID}</SpinningButton>
        </div>
      )}
    </>
  );
};

BankIDSelector.propTypes = {
  children: PropTypes.node.isRequired,
  isSelected: PropTypes.bool.isRequired,
  onSelect: PropTypes.func.isRequired,
};

const Logins = ({ availableLoginMethods, isLoginFormSelected, isBankIDSelected, setSelectedLoginMethod, ...props }) => {
  const translate = useTranslate();
  return (
    <div className={styles.loginFormsOuterContainer}>
      <div className={styles.loginFormContainer}>
        {availableLoginMethods.length > 1 && !(isLoginFormSelected || isBankIDSelected) && (
          <div className={classNames(styles.sectionTitle, styles.textAlignLeft)}>
            {translate("SELECT") + " " + translate("AUTHENTICATION_METHOD")}
          </div>
        )}
      </div>
      {availableLoginMethods.map(method => {
        if (method === LOGIN_METHODS.BANKID && !isLoginFormSelected) {
          return (
            <div className={styles.loginFormContainer} key={method}>
              <BankIDSelector isSelected={isBankIDSelected} onSelect={() => setSelectedLoginMethod(method)}>
                <BankIDLoginAlternatives />
              </BankIDSelector>
            </div>
          );
        } else if (method === LOGIN_METHODS.LOGIN_FORM && !isBankIDSelected) {
          return (
            <LoginFormLogin
              key={method}
              isSelected={isLoginFormSelected}
              onSelect={() => setSelectedLoginMethod(method)}
              {...props}
            />
          );
        }
        return null;
      })}
    </div>
  );
};
Logins.propTypes = {
  availableLoginMethods: PropTypes.arrayOf(PropTypes.string).isRequired,
  isLoginFormSelected: PropTypes.bool.isRequired,
  isBankIDSelected: PropTypes.bool.isRequired,
  setSelectedLoginMethod: PropTypes.func.isRequired,
};

const LoginMethodsContainer = ({ availableLoginMethods, reset, selectedLoginMethod, ...props }) => {
  const translate = useTranslate();
  return (
    <>
      <Logins availableLoginMethods={availableLoginMethods} {...props} />
      {selectedLoginMethod && availableLoginMethods.length > 1 && (
        <div className={styles.loginFormContainer} onClick={reset}>
          <Button theme={THEME.PLAIN_PRIMARY}>{translate("CHANGE") + " " + translate("AUTHENTICATION_METHOD")}</Button>
        </div>
      )}
    </>
  );
};
LoginMethodsContainer.propTypes = {
  availableLoginMethods: PropTypes.arrayOf(PropTypes.string).isRequired,
  reset: PropTypes.func.isRequired,
  selectedLoginMethod: PropTypes.string,
};

const LoginWelcome = ({ ...props }) => {
  const { availableLoginMethods, checkValidLoginMethods } = useAvailableLoginMethods();
  const {
    selectedLoginMethod,
    setSelectedLoginMethod,
    reset,
    isLoginFormSelected,
    isBankIDSelected,
  } = useSelectedLoginMethod(availableLoginMethods);

  useEffect(() => {
    checkValidLoginMethods();
  }, [checkValidLoginMethods]);

  return (
    <Welcome {...props}>
      <LoginMethodsContainer
        availableLoginMethods={availableLoginMethods}
        reset={reset}
        selectedLoginMethod={selectedLoginMethod}
        setSelectedLoginMethod={setSelectedLoginMethod}
        isLoginFormSelected={isLoginFormSelected}
        isBankIDSelected={isBankIDSelected}
        {...props}
      />
    </Welcome>
  );
};

LoginWelcome.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  customCssClass: PropTypes.string,
};

export default LoginWelcome;
